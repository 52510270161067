import React from "react";
import ReactDOM from "react-dom/client";

// Styles imports
import "@mantine/core/styles.css";
// import "rsuite/dist/rsuite.min.css"
import "rsuite/dist/rsuite-no-reset.min.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./sass/main.scss";

import App from "./App";
import { Provider } from "react-redux";

// Redux
import { store } from "./app/store";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>
);
