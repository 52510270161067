import React from "react";
import { FcGoogle } from "react-icons/fc";

type Props = {
	onclick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

function GoogleSignupButton({ onclick }: Props) {
	return (
		<button type="button" className="g-auth-btn" onClick={onclick}>
			<FcGoogle size={24} />
			<span>Continue with Google</span>
		</button>
	);
}

export default GoogleSignupButton;
