import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

function Copyright(props: any) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			<Link to="/">Flavor Feed</Link> {new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

export default Copyright;
