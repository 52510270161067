import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AuthNavbar from "./AuthNavbar";
import Copyright from "../../components/Copyright";
import GoogleSignupButton from "../../components/buttons/GoogleSignupButton";
import FbAuthButton from "../../components/buttons/FbAuthButton";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { login } from "../../api/api/authApi";
import { selectAuth, setCredentials } from "../../features/authSlice";
import toast from "react-hot-toast";
import ToastAlert from "./ToastAlert";
import { useToaster } from "rsuite";
import AppNotification from "../../components/AppNotification";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

interface User {
	email: string;
	password: string;
}

// A custom validation function. This must return an object
// which keys are symmetrical to our values/initialValues
const validate = (values: User) => {
	const errors: User | any = {};

	if (!values.email) {
		errors.email = "Required";
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = "Invalid email address";
	}

	if (!values.password) {
		errors.password = "Password required";
	} else if (values.password.length < 8) {
		errors.password = "Must be 8 characters or more";
	}

	return errors;
};

export default function Login() {
	const { user, isAuthenticated } = useAppSelector(selectAuth);
	const toaster = useToaster();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	let location = useLocation();
	let from = location.state?.from?.pathname || "/";

	useEffect(() => {
		if (user && isAuthenticated) {
			navigate(from, { replace: true });
		}
	}, [user, isAuthenticated]);

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		// validate,
		onSubmit: async (values, actions) => {
			const res = await login(values);
			console.log("Response ", res);

			if (res.success) {
				dispatch(setCredentials(res.data));
				// toast.success("Welcome back!");
				toaster.push(
					<AppNotification
						type="success"
						message=""
						header="Welcome back!"
					/>,
					{ placement: "bottomEnd" }
				);
				navigate(from, { replace: true });
				actions.resetForm();
			} else {
				// toast.error(res.message);
			}
		},
	});

	// useEffect(() => {
	// 	console.log(formik.errors);
	// }, [formik.errors]);

	// useEffect(() => {
	// 	const fetchBackgroundImages = async () => {
	// 		const baseUrl = process.env.REACT_APP_UNSPLASH_BASE_URL;
	// 		const CLIENT_ID = process.env.REACT_APP_UNSPLASH_ACCESS_KEY;
	// 		const url = `${baseUrl}/photos/random?client_id=${CLIENT_ID}&query=dish&count=5`;

	// 		try {
	// 			const response: AxiosResponse = await axios.get(url);
	// 			console.log("response", response);
	// 			setPics(response.data);
	// 			setSinglePic(response.data[0].urls.small);
	// 		} catch (err) {
	// 			console.log(err);
	// 		}
	// 	};

	// 	fetchBackgroundImages();
	// }, []);

	return (
		<ThemeProvider theme={defaultTheme}>
			<AuthNavbar />
			<ToastAlert />

			{/* <div
        style={{
          background: "center / contain no-repeat url(" + singlePic + ")",
          zIndex: 2,
          position: "absolute",
          top: 0,
          left: 0,
          height: 500,
          width: 500,
        }}
      ></div> */}
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
						marginTop: 5,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: "#54AC5A" }}>
						<LockOutlinedIcon />
					</Avatar>

					<Typography component="h1" variant="h5">
						Welcome back!
					</Typography>

					<Box
						component="form"
						onSubmit={formik.handleSubmit}
						noValidate
						sx={{ mt: 1 }}
					>
						<TextField
							margin="normal"
							fullWidth
							size="small"
							id="email"
							label="Email"
							name="email"
							value={formik.values.email}
							onChange={formik.handleChange}
							autoComplete="email"
							autoFocus
						/>

						<TextField
							margin="normal"
							fullWidth
							size="small"
							id="password"
							label="Password"
							name="password"
							value={formik.values.password}
							onChange={formik.handleChange}
							autoComplete="current-password"
							autoFocus
							type="password"
						/>

						{/* {formFields.map((item) => {
							return (
								<TextField
									margin="normal"
									fullWidth
									size="small"
									id={item.id}
									label={item.label}
									name={item.id}
									value={item.}
									onChange={formik.handleChange}
									autoComplete={
										item.id === "email" ? "email" : "current-password"
									}
									{...(item.id === "email"
										? { autoFocus: true }
										: { type: "password" })}
								/>
							);
						})} */}

						<FormControlLabel
							control={<Checkbox value="remember" color="primary" />}
							label="Remember me"
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2, backgroundColor: "#54AC5A" }}
						>
							Log In
						</Button>

						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-around",
								width: "100%",
							}}
						>
							<hr style={{ width: "45%" }} />
							<span>OR</span>
							<hr style={{ width: "45%" }} />
						</div>

						<div className="external-auth-btns-container">
							<GoogleSignupButton
								onclick={() => console.log("login with google")}
							/>
							<FbAuthButton
								onclick={() => console.log("login with fb")}
							/>
						</div>

						<Grid container>
							<Grid item xs>
								<Typography variant="body2">
									<Link to="/forgot-password">Forgot password?</Link>
								</Typography>
							</Grid>
							<Grid item>
								<Typography variant="body2">
									Don't have an account?{" "}
									<Link to="/signup">Sign Up</Link>
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Copyright sx={{ mt: 8, mb: 4 }} />
			</Container>
		</ThemeProvider>
	);
}
