import { apiInstance } from "..";

type Profile = {
	firstName: string;
	lastName: string;
	email: string;
	username: string;
};

export const getUserProfile = async () => {
	try {
		const { data } = await apiInstance.get(`/users`);
		console.log(data);
		return data;
	} catch (error: any) {
		console.log(error);

		if (error.response) {
			return error.response.data;
		}
	}
};

export const updateProfile = async (payload: Profile) => {
	try {
		const { data } = await apiInstance.patch("/users", payload);
		return data;
	} catch (error: any) {
		console.log(error);

		if (error.response) {
			return error.response.data;
		}
	}
};
