import React, { useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Copyright from "../../components/Copyright";
import { selectUser, setCredentials } from "../../features/authSlice";
import AuthNavbar from "./AuthNavbar";
import GoogleSignupButton from "../../components/buttons/GoogleSignupButton";
import FbAuthButton from "../../components/buttons/FbAuthButton";
import { signup } from "../../api/api/authApi";
import toast from "react-hot-toast";
import ToastAlert from "./ToastAlert";

// import LOGO from "../../assets/logo.png";

const theme = createTheme();

type ErrorsType = {
	message: string | undefined;
	password: string | undefined;
	email: string | undefined;
};

const validate = (values: any) => {
	const errors: ErrorsType = {
		message: undefined,
		password: undefined,
		email: undefined,
	};

	// if (!values.email && !values.password) {
	// 	errors.email = "Field required";
	// 	errors.password = "Field required";
	// 	return errors;
	// }

	const valuesArray = Object.values(values);

	for (let value of valuesArray) {
		if (!value) {
			errors.message = "All fields required";
		}
	}

	if (!values.password) {
		errors.password = "You must create a password";
	}

	if (values.password !== values.confirmPassword) {
		errors.message = "Password must match";
		return errors;
	}

	if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = "Invalid email address";
	}

	return errors;
};

export default function Signup() {
	// const { user, isAuthenticated } = useSelector(selectUser);
	const navigate = useNavigate();
	let location = useLocation();
	const dispatch = useAppDispatch();
	let from = location.state?.from?.pathname || "/";

	const formik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			username: "",
			email: "",
			password: "",
		},
		// validate,
		onSubmit: async (values, actions) => {
			console.log("User", values);
			const res = await signup(values);
			console.log("Response ", res);

			if (res.success) {
				dispatch(setCredentials(res.data));
				toast.success("Welcome back!");
				navigate(from, { replace: true });
				actions.resetForm();
			} else {
				toast.error(res.message);
			}
		},
	});

	// useEffect(() => {
	//   if (user && isAuthenticated) {
	//     navigate(from, { replace: true });
	//   }
	// }, [user, isAuthenticated]);

	return (
		<div className="signup-page">
			<ThemeProvider theme={theme}>
				<AuthNavbar />
				<ToastAlert />
				<Container component="main" maxWidth="xs">
					<CssBaseline />
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Link
							to="/"
							style={{
								margin: "0 0 60px 0",
							}}
						>
							{/* <img src={LOGO} alt="logo" width="300" /> */}
						</Link>
						{/* {!!Object.keys(formik.errors).length && (
            <div style={{ marginBottom: 20 }} className="register_page-error">
              {formik.errors.message}
            </div>
          )} */}
						<Typography component="h1" variant="h5">
							Sign up
						</Typography>

						<Box
							component="form"
							noValidate
							onSubmit={formik.handleSubmit}
							sx={{ mt: 3 }}
						>
							<Grid container spacing={2}>
								{/* <Grid item xs={6}>
									<TextField
										fullWidth
										id="fullName"
										label="Full Name"
										name="fullName"
										value={formik.values.fullName}
										onChange={formik.handleChange}
										autoFocus
										size="small"
									/>
								</Grid> */}

								<Grid item xs={12} sm={6}>
									<TextField
										autoComplete="given-name"
										name="firstName"
										fullWidth
										id="firstName"
										label="First Name"
										value={formik.values.firstName}
										onChange={formik.handleChange}
										autoFocus
										size="small"
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										fullWidth
										id="lastName"
										label="Last Name"
										name="lastName"
										value={formik.values.lastName}
										onChange={formik.handleChange}
										autoComplete="family-name"
										size="small"
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										id="username"
										label="Username"
										name="username"
										value={formik.values.username}
										onChange={formik.handleChange}
										// autoComplete="email"
										autoFocus
										size="small"
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										id="email"
										label="Email"
										name="email"
										value={formik.values.email}
										onChange={formik.handleChange}
										autoComplete="email"
										autoFocus
										size="small"
									/>
								</Grid>

								<Grid item xs={12}>
									<TextField
										fullWidth
										name="password"
										label="Password"
										type="password"
										id="password"
										size="small"
										value={formik.values.password}
										onChange={formik.handleChange}
										autoComplete="new-password"
										// error={formik.errors.password ? true : false}
										// helperText={formik.errors.password}
									/>
								</Grid>

								<Grid item xs={12}>
									<Typography
										sx={{
											padding: "0px 15px",
											textAlign: "center",
										}}
										variant="body2"
									>
										By signing up, you agree to our{" "}
										<Link to="#">Terms of Service</Link>,{" "}
										<Link to="#">Privacy Policy</Link> and Cookies
										Policy.
									</Typography>
								</Grid>
							</Grid>
							<LoadingButton
								loading={formik.isSubmitting}
								type="submit"
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 2, backgroundColor: "#54AC5A" }}
							>
								Sign Up
							</LoadingButton>

							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-around",
									width: "100%",
								}}
							>
								<hr style={{ width: "45%" }} />
								<span>OR</span>
								<hr style={{ width: "45%" }} />
							</div>

							<div className="external-auth-btns-container">
								<GoogleSignupButton
									onclick={() => console.log("signing up with google")}
								/>

								<FbAuthButton
									onclick={() => console.log("signing up with  fb")}
								/>
							</div>

							{/* <Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							Sign Up
						</Button> */}
							<Grid container justifyContent="flex-end">
								<Grid item>
									<Typography variant="body2">
										Already have an account?{" "}
										<Link
											style={{ textDecoration: "underline" }}
											to="/login"
										>
											Sign in
										</Link>
									</Typography>
								</Grid>
							</Grid>
						</Box>
					</Box>
					<Copyright
						sx={{
							mt: 5,
							display: "flex",
							gap: 0.75,
							justifyContent: "center",
						}}
					/>
				</Container>
			</ThemeProvider>
		</div>
	);
}
