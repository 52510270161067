import { apiInstance, authInstance } from "..";
import { AxiosResponse } from "axios";

type SignupPayload = {
	firstName: string;
	lastName: string;
	username: string;
	email: string;
	password: string;
};

type LoginPayload = {
	email: string;
	password: string;
};

export const login = async (payload: LoginPayload) => {
	try {
		const { data } = await authInstance.post("/auth/login", payload);
		return data;
	} catch (error: any) {
		console.log(error);

		if (error.response) {
			return error.response.data;
		}
	}
};

export const signup = async (payload: SignupPayload) => {
	try {
		const { data } = await authInstance.post("/auth/signup", payload);
		return data;
	} catch (err: any) {
		console.log(err);

		if (err.response) {
			return err.response.data;
		}
	}
};

export const verifyAuth = async () => {
	try {
		const { data } = await apiInstance.get("/auth/verify");

		if (data.success) {
			return data;
		}
	} catch (err: any) {
		console.log(err);
		if (err.response) {
			return err.response;
		}
	}
};

// Restaurant auth handlers
export const restaurantLogin = async () => {};

export const signupRestaurant = async (data: any) => {
	try {
		const options = { headers: { "Content-Type": "application/json" } };
		const res: AxiosResponse = await authInstance.post(
			"business/auth/become-partner",
			data,
			options
		);

		return res.data;
	} catch (err) {
		console.log(err);
	}
};

export const refreshToken = async () => {
	try {
		const res = await authInstance.get("/api/auth/refresh");
	} catch (err) {
		console.log(err);
	}
};
