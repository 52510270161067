import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Panel, Divider } from "rsuite";
import {
	Stack,
	Box,
	PasswordInput,
	Button,
	Tabs,
	rem,
	Group,
} from "@mantine/core";
import AppButton from "../../components/buttons/AppButton";

const styles = {
	label: {
		// fontSize: 15,
		fontWeight: 400,
	},
};

function Settings() {
	const navigate = useNavigate();
	const [value, setValue] = useState("account");
	const { tabValue } = useParams();

	return (
		<div className="settings__page">
			<h2>Settings</h2>
			<Tabs
				value={tabValue}
				defaultValue={value}
				onChange={(value) => navigate(`/settings/${value}`)}
			>
				<Tabs.List>
					<Tabs.Tab value="account">Account</Tabs.Tab>
					<Tabs.Tab value="password">Password</Tabs.Tab>
					<Tabs.Tab value="notifications">Notifications</Tabs.Tab>
				</Tabs.List>

				<Tabs.Panel value="account">Gallery tab content</Tabs.Panel>

				<Tabs.Panel value="password">
					<Panel
						bordered
						header="Change Password"
						style={{ marginTop: 50 }}
					>
						<Stack gap={35}>
							<Box maw={500}>
								<Stack>
									<PasswordInput
										label="Current Password"
										name="password"
										// value={state.password}
										placeholder="Password"
										styles={styles}
										// {...form.getInputProps("password")}
									/>

									<PasswordInput
										label="New Password"
										name="password"
										// value={state.password}
										placeholder="Password"
										styles={styles}
										// {...form.getInputProps("password")}
									/>

									<PasswordInput
										label="Confirm New Password"
										name="password"
										// value={state.password}
										placeholder="Password"
										styles={styles}
										// {...form.getInputProps("password")}
									/>
								</Stack>
							</Box>
							<Group justify="flex-end">
								<Button bg="#F4F4F4" style={{ color: "#1B1212" }}>
									Cancel
								</Button>
								<AppButton label="Update Password">
									Change Password
								</AppButton>
							</Group>
						</Stack>
					</Panel>
				</Tabs.Panel>

				<Tabs.Panel value="notifications">Settings tab content</Tabs.Panel>
			</Tabs>
		</div>
	);
}

export default Settings;
