import React, { useEffect, useState } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { useClickOutside } from "@mantine/hooks";
import List from "./List";

function Searchbar() {
	const [input, setInput] = useState<string>("");
	const [results, setResults] = useState([]);
	const [open, setOpen] = useState(false);
	const ref = useClickOutside(() => setOpen(false));

	const handleSearch = (e: any) => {
		console.log(e.target);
	};

	const handleFocus = () => {
		if (input.length) {
			setOpen(true);
		}
	};

	useEffect(() => {
		if (input.length) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	}, [input]);

	return (
		<div ref={ref} className="searchbar_container">
			<div className="searchbar">
				<FaLocationDot className="location-icon" size={14} />
				<input
					onFocus={handleFocus}
					type="text"
					placeholder="Search restaurants"
					value={input}
					onChange={(e) => setInput(e.target.value)}
					onKeyDown={handleSearch}
				/>
			</div>
			<button onClick={handleSearch} className="search-btn">
				Search
			</button>
			{open && <List results={results} input={input} />}
		</div>
	);
}

export default Searchbar;
