import React, { useEffect, useState } from "react";
import Searchbar from "../../components/search/Searchbar";
import { Typography } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import SearchResultView from "./SearchResultView";
import DishCard from "../../components/DishCard";

function Landing() {
	const [images, setImages] = useState<string[]>([]);
	// const [showcase, setShowcase] = useState<string[]>([]);

	// const handlePicturesRotation = () => {
	//   const shuffled = images.sort(() => 0.5 - Math.random());
	//   setShowcase(shuffled.slice(0, 17));
	// };

	const fetchPexels = async () => {
		const baseUrl = process.env.REACT_APP_PEXELS_BASE_URL;
		const API_KEY = process.env.REACT_APP_PEXELS_KEY;
		const url = `${baseUrl}/search?query=dish&per_page=17`;
		const options = { headers: { Authorization: API_KEY } };

		try {
			const response: AxiosResponse = await axios.get(url, options);
			console.log("response", response);

			const pics = response.data.photos.map((item: any) => item.src.medium);

			// const shuffled = images.sort(() => 0.5 - Math.random());

			setImages(pics);
			// setShowcase(shuffled.slice(0, 17));
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		// const fetchBackgroundImages = async () => {
		// 	const baseUrl = process.env.REACT_APP_UNSPLASH_BASE_URL;
		// 	const CLIENT_ID = process.env.REACT_APP_UNSPLASH_ACCESS_KEY;
		// 	const url = `${baseUrl}/photos/random?client_id=${CLIENT_ID}&query=dish&count=20`;

		// 	try {
		// 		const response: AxiosResponse = await axios.get(url);
		// 		console.log("response", response);
		// 		console.log(response.data.length);
		// 		const images = response.data.map((item: any) => item.urls.small);

		// 		// const shuffled = images.sort(() => 0.5 - Math.random());

		// 		setImages(images);
		// 		// setShowcase(shuffled.slice(0, 17));
		// 	} catch (err) {
		// 		console.log(err);
		// 	}
		// };

		// fetchBackgroundImages();
		fetchPexels();

		// let interval: any;
		// if (!images.length) {
		//   console.log("fetching data");
		//   fetchBackgroundImages();
		// } else {
		//   if (!showcase.length) {
		//     interval = setInterval(() => {
		//       const shuffled = images.sort(() => 0.5 - Math.random());

		//       setShowcase(shuffled.slice(0, 17));
		//     }, 4000);
		//   }
		// }

		// return () => clearInterval(interval);
	}, []);

	return (
		<main id="home">
			<section className="search_section">
				<Typography variant="h4" component="h4">
					Find the dish you're looking for
				</Typography>
				<Searchbar />
			</section>

			{/* <SearchResultView /> */}

			<section className="showcase_section">
				<ul className="pics_navigation">
					{images.map((pic: string, i) => (
						<li key={i}>
							<img className="pic" src={pic} alt="dishes" />
						</li>
					))}
				</ul>
			</section>
		</main>
	);
}

export default Landing;
