import React from "react";
import { FaFacebook } from "react-icons/fa";

type Props = {
	onclick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

function FbAuthButton({ onclick }: Props) {
	return (
		<button type="button" className="fb-auth-btn" onClick={onclick}>
			<FaFacebook color="#fff" size={24} />
			<span>Continue with Facebook</span>
		</button>
	);
}

export default FbAuthButton;
