import React from "react";
import { Route, Routes } from "react-router-dom";
import PublicLayout from "../layouts/PublicLayout";
import Landing from "../pages/landing/Landing";
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import RequireAuth from "./RequireAuth";
import Settings from "../pages/settings";
import MyFavorite from "../pages/myFavorites";
import PersistLogin from "./PersistLogin";
import NotFound from "../pages/NotFound";
import AuthRedirect from "./AuthRedirect";
import Profile from "../pages/profile/Profile";

function Root() {
	return (
		<Routes>
			<Route path="/">
				<Route element={<AuthRedirect />}>
					<Route path="login" element={<Login />} />
					<Route path="signup" element={<Signup />} />
				</Route>

				<Route element={<PersistLogin />}>
					<Route element={<PublicLayout />}>
						<Route index element={<Landing />} />
						<Route path="home" element={<Landing />} />

						<Route element={<RequireAuth />}>
							<Route path="profile" element={<Profile />} />
							<Route path="favorites" element={<MyFavorite />} />
							<Route path="settings" element={<Settings />} />
							<Route path="settings/:tabValue" element={<Settings />} />
						</Route>
					</Route>
				</Route>

				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	);
}

export default Root;
