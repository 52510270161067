import React from "react";
import { Notification } from "rsuite";

type Props = {
	type: "info" | "success" | "warning" | "error" | undefined;
	header: string;
	message: string;
};

function AppNotification({ header, type, message }: Props) {
	return (
		<Notification closable header={header} type={type}>
			{message}
		</Notification>
	);
}

export default AppNotification;
