import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";
import { selectUser } from "../features/authSlice";
import { useAppSelector } from "../hooks";
import UserNavbar from "../components/navbar/UserNavbar";

const PublicLayout = () => {
	const { user } = useAppSelector(selectUser);

	return (
		<div>
			{user ? <UserNavbar /> : <Navbar />}
			<main id="main">
				<Outlet />
			</main>
		</div>
	);
};

export default PublicLayout;
