import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import Root from "./routes/root";
import { createTheme, MantineProvider } from "@mantine/core";

const theme = createTheme({
	/** Put your mantine theme override here */
});

const router = createBrowserRouter([{ path: "/*", element: <Root /> }]);

function App() {
	// const dispatch = useAppDispatch();

	// useEffect(() => {
	// 	const token = localStorage.getItem("access-token");

	// 	if (token) {
	// 		// handle app initialization
	// 	}
	// }, []);

	return (
		<MantineProvider theme={theme}>
			<CssBaseline />
			<RouterProvider router={router} />
		</MantineProvider>
	);
}

export default App;
