import React from "react";
import { FaLocationDot } from "react-icons/fa6";

type Props = {
	results: object[];
	input: string | undefined;
};

const mockData = [
	{ name: "Cavalli Pizza", address: "3601 Regent Blvd, Irving, TX" },
	{ name: "Neony Pizza", address: "829 W Davis St, Dallas, TX" },
	{ name: "Bowen House", address: "2614 Boll St, Dallas, TX" },
	{ name: "Avanti", address: "2720 McKinney Ave, Dallas, TX" },
	{ name: "The Queen of Sheba", address: "14875 Inwood Rd, Addison, TX" },
];

const List = ({ results, input }: Props) => {
	const filteredData = results.filter((item: any) => {
		if (input === "") {
			return item;
		} else {
			return item.text.toLowerCase().includes();
		}
	});

	return (
		<div className="search_results">
			<ul className="nav-list">
				{mockData.map((item, i) => {
					return (
						<li key={i.toString()} className="found-item">
							<span className="business-name">{item.name}</span>
							<span className="business-address">
								<FaLocationDot
									color="rgba(29, 28, 28, 0.87)"
									className="location-icon"
									size={14}
								/>
								{item.address}
							</span>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default List;
