import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

function AuthNavbar() {
	return (
		<header className="auth_header">
			<Link className="logo" to="/">
				<Typography variant="h5">Flavor Feed</Typography>
			</Link>
		</header>
	);
}

export default AuthNavbar;
