import React from "react";
import { Link, useRouteError } from "react-router-dom";

function NotFound() {
	const error: any = useRouteError();
	console.error(error);
	return (
		<div id="not_found">
			<div className="content">
				<span className="status_code">404</span>
				<div className="text_wrapper">
					<span className="main_text">Oops! You're lost.</span>
					<br />
					<span className="helper_text">
						The page you are looking for was not found.
					</span>
				</div>
			</div>

			<Link to="/" className="back_button">
				Take me home
			</Link>

			<p>{/* <i>{error.statusText || error.message}</i> */}</p>
		</div>
	);
}

export default NotFound;
