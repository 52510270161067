import React from "react";
import { PacmanLoader } from "react-spinners";

const AppLoader = () => {
	return (
		<div className="app-loader">
			<PacmanLoader color="#36d7b7" size={10} />
		</div>
	);
};

export default AppLoader;
