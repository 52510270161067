import React, { useEffect, useState } from "react";
import { Button, Stack, Group, TextInput } from "@mantine/core";
import AppButton from "../../components/buttons/AppButton";
import { getUserProfile, updateProfile } from "../../api/api/userApi";
import { useAppSelector } from "../../hooks";
import { selectAuth } from "../../features/authSlice";

interface IUser {
	firstName: string;
	lastName: string;
	email: string;
	username: string;
}

function Profile() {
	const { user } = useAppSelector(selectAuth);

	const [state, setState] = useState({
		firstName: "",
		lastName: "",
		email: "",
		username: "",
	});

	const [updates, setUpdates] = useState<IUser>(state);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.currentTarget;
		setUpdates({ ...updates, [name]: value });
	};

	const save = async () => {
		if (JSON.stringify(state) !== JSON.stringify(updates)) {
			const res = await updateProfile(updates);
			console.log(res);
		}
	};

	useEffect(() => {
		if (user) {
			const fetchUserData = async () => {
				const res = await getUserProfile();
				setState(res.data);
				setUpdates(res.data);
			};

			fetchUserData();
		}
	}, [user]);

	return (
		<div className="profile__page">
			<h2>Profile</h2>

			<Stack>
				<Group grow>
					<TextInput
						label="First Name"
						name="firstName"
						value={updates.firstName}
						onChange={handleChange}
						// disabled
					/>
					<TextInput
						label="Last Name"
						name="lastName"
						value={updates.lastName}
						onChange={handleChange}
						// disabled
					/>
				</Group>

				<TextInput
					label="Email Address"
					value={updates.email}
					name="email"
					onChange={handleChange}
					// disabled
				/>
				<TextInput
					label="Username"
					value={updates.username}
					name="username"
					onChange={handleChange}
					// disabled
				/>

				<Group justify="flex-end">
					<Button bg="#F4F4F4" style={{ color: "#1B1212" }}>
						Cancel
					</Button>

					<AppButton label="Save changes" onClick={save} />
				</Group>
			</Stack>
		</div>
	);
}

export default Profile;
