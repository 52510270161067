import React from "react";
import { Toaster } from "react-hot-toast";

function ToastAlert() {
	return (
		<Toaster
			toastOptions={{
				success: {
					style: {
						background: "green",
					},
				},
				error: {
					style: {
						color: "#fff",
						// background: "#dc3545",
						background: "#e55353",
					},
				},
			}}
		/>
	);
}

export default ToastAlert;
